function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { InvoiceType } from '@ezetech/swag-presentation-templates/dist/invoices/interfaces';
import { createSelector } from 'reselect';
import { INVENTORY_BUY_CREDITS_POPUP } from 'swag-client-common/constants';
import { shownPopupSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { replacementPriceRangeValueSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { resellerAdminModeSelector, unauthenticatedUserAlreadyExistsSelector, userAdminPoliciesSelector, userAdminRoleSelector } from 'swag-client-common/redux/user/user.selectors';
import { buildError } from 'swag-client-common/utils/build-inventory-payment-errors';
import { PAYMENT_STATES } from 'swag-client-common/utils/constants';
import { getDifferenceStr, getFinalTotal, getItemPriceInfo } from 'swag-client-common/utils/order-item.utils';
import { sortCards } from 'swag-client-common/utils/stripe-cards.utils';
import { getRelatedItemsBasedOnProvidedId, getSetupFeeForBoxProduct, getSetupFeeForLogoProduct } from 'swag-common/business-logic';
import { canUserManagePermissions } from 'swag-common/business-logic/inventory';
import { getDataForGettingItemShippingCost, getItemShippingCost } from 'swag-common/business-logic/order/get-item-shipping-cost.service';
import getSetupFeeDeductionDiscount from 'swag-common/business-logic/order/get-setup-fee-deduction-discount.logic';
import { recalculateProceededOrder } from 'swag-common/business-logic/order/recalculate-proceeded-order.logic';
import { ORDER_STATUSES } from 'swag-common/constants/hubspot';
import { DISPLAY_ALL, PREPAYMENT_RECEIVED } from 'swag-common/constants/item-statuses.constants';
import { ACH } from 'swag-common/constants/payment-methods';
import { PromocodeMeasurementEnum } from 'swag-common/constants/promocode';
import { POLICIES } from 'swag-common/constants/roles';
import { COUNTRY_CODES } from 'swag-common/constants/shipping.constants';
import { USER_ROLES } from 'swag-common/constants/user-roles';
import { REPLACEMENT_ITEM_STATUSES } from 'swag-common/interfaces';
import { isCancelledItem } from 'swag-common/utils/order';
import { formatOrderDataForInvoice } from 'swag-common/utils/order/format-order-data-for-invoice.util';
import { isBox } from 'swag-common/utils/order/item-is-box.util';
import { productionCostCalculate } from 'swag-common/utils/order/production-cost-calculate.utils';
import { setupFeeForItem } from 'swag-common/utils/order/setup-fee-for-item.utils';
import { getInternationalDeliveryErrorMsg } from 'swag-common/utils/order/validate-order-for-international-shipping.util';
import { Price } from 'swag-common/utils/price';
import { checkIsInvoiceMode, checkIsPresentationMode } from 'swag-common/utils/reseller/check-is-reseller-admin-mode.util';
import { getCountryByCode } from 'swag-common/utils/shipping';
import { absoluteDiscountSelector, cartItemsSelector, cartRootSelector, isSetupFeeFreeSelector, isShippingFreeSelector, isTaxFreeSelector, noInternationalShippingItemsSelector, orderTotalPriceSelector, resellerInvoiceNumSelector, swagSpaceOrderTotalSelector, taxesPriceSelector } from '../cart/cart.selectors';
import { deliveryMustBeDoneToInventoriesIdsSelector, inventoriesPaymentSettingsSelector, inventoriesSelector, selectedCardSelector } from '../inventory/inventory.selectors';
import { PAYMENT_DETAILS_FORM } from '../redux.constants';
import { invoiceDiscountDataSelector, invoiceLineItemsSelector } from '../reseller-invoice/reseller-invoice.selectors';
import { userIdSelector } from '../user/user.selectors';
import { acceptContractSelector, deliverySelector, isDeliveryAddressValidSelector } from './checkout.selectors-other';
import { formatCard, getCardsByInventories } from './checkout.utils';
import { checkIsTenantRestriction, STATUSES_WHEN_CLOSE_BUTTON_SHOWN, TENANT_ERROR_TEXT } from './checkout.utils-other';
export * from './checkout.selectors-other';
const MAX_COMMENT_LENGTH = 500;
const MAX_REPLACEMENT_ITEMS_PER_PAGE = 10;
const rootSelector = state => state.checkout;
export const isLoggedInSelector = state => state.user.isLoggedIn;
export const deliverToFulfillmentCenterSelector = createSelector(rootSelector, checkout => checkout.deliverToFulfillmentCenter);
export const isInventoryApprovalFlowSelector = createSelector(rootSelector, checkout => checkout.isInventoryApprovalFlow);
export const neededTokensSelector = createSelector(rootSelector, checkout => checkout.neededTokens);
export const creditsSelector = createSelector(rootSelector, checkout => checkout.credits);
export const checkoutMoreToBuyCreditsSelector = createSelector(creditsSelector, credits => credits.moreToBuy);
export const checkoutAvailableCreditsSelector = createSelector(creditsSelector, credits => credits.available);
export const hasSomeCreditsAvailableSelector = createSelector(checkoutAvailableCreditsSelector, available => available > 0);
export const checkoutIsBuyMoreCreditsFlowSelector = createSelector(shownPopupSelector, popup => popup === INVENTORY_BUY_CREDITS_POPUP);
export const checkoutNeedToBuyCreditsSelector = createSelector(orderTotalPriceSelector, checkoutAvailableCreditsSelector, (total, credits) => Math.ceil((total - credits) / 100));
export const checkoutPendingCreditsSelector = createSelector(creditsSelector, credits => credits.pending);
export const hasSomeCreditsPendingSelector = createSelector(checkoutPendingCreditsSelector, pending => pending > 0);
export const orderForCustomStoreSelector = createSelector(rootSelector, checkout => checkout.orderForCustomStore);
export const inventoryAcceptedSelector = createSelector(rootSelector, checkout => checkout.inventoryAccepted);
export const isCreateMyInventorySelectedSelector = createSelector(rootSelector, checkout => checkout.isCreateMyInventorySelected);
export const orderPublicIdSelector = createSelector(rootSelector, checkout => checkout.publicId);
export const widgetIsTouchedSelector = createSelector(rootSelector, checkout => checkout.atiWidgetIsTouched);
export const deliveryCompanySelector = createSelector(rootSelector, checkout => checkout.delivery.company);
export const paymentStateSelector = createSelector(rootSelector, checkout => checkout.paymentState);
export const previousPaymentStateSelector = createSelector(rootSelector, checkout => checkout.previousPaymentState);
export const replacementOrderStateSelector = createSelector(rootSelector, checkout => checkout.replacementOrder);
export const ksFlowSelector = createSelector(rootSelector, checkout => checkout.ksFlow);
export const loginEmailSelector = createSelector(rootSelector, checkout => checkout.userEmail);
export const emailVerifiedSelector = createSelector(rootSelector, checkout => checkout.emailVerified);
export const passwordNotSetSelector = createSelector(rootSelector, checkout => checkout.passwordNotSet);
export const errorSelector = createSelector(rootSelector, ({
  err
}) => {
  if (!err) {
    return null;
  }
  return _objectSpread({}, err, {
    message: err.message.replace('Error: ', '')
  });
});
export const emailSelector = createSelector(deliverySelector, delivery => delivery.email);
export const deliveryNoteSelector = createSelector(deliverySelector, delivery => delivery.deliveryNote);
export const paymentStateLoadingSelector = createSelector(rootSelector, checkout => checkout.loading);
const isUsaDeliveryAddressSelector = createSelector(deliverySelector, delivery => delivery.country === COUNTRY_CODES.US);
export const addressSummarySelector = createSelector(deliverySelector, isUsaDeliveryAddressSelector, (delivery, isUSA) => {
  const {
    city,
    state,
    unit,
    country,
    zip
  } = delivery;
  let address = delivery.address;
  if (unit) {
    address = address.replace(unit, '').trim();
  }
  const addressData = isUSA ? [address, unit, city, state, zip] : [address, unit, city, state, getCountryByCode(country), zip];
  return addressData.filter(Boolean).join(', ');
});
export const loginDataSelector = createSelector(loginEmailSelector, emailVerifiedSelector, isLoggedInSelector, passwordNotSetSelector, (userEmail, emailVerified, isLoggedIn, passwordNotSet) => ({
  userEmail,
  emailVerified,
  isLoggedIn,
  passwordNotSet
}));
export const defaultInventoryAndUserCardsSelector = createSelector(deliveryMustBeDoneToInventoriesIdsSelector, inventoriesPaymentSettingsSelector, (inventories, settings) => {
  if (inventories.length !== 1) {
    return {
      userCard: null,
      inventoryCard: null
    };
  }
  const [id] = inventories;
  const setting = settings.find(s => s.inventoryId === id);
  if (!setting) {
    return {
      userCard: null,
      inventoryCard: null
    };
  }
  const {
    cards,
    defaultCard,
    defaultInventoryCard
  } = setting;
  const userCard = cards.find(({
    _id
  }) => String(_id) === defaultCard);
  const inventoryCard = cards.find(({
    _id
  }) => String(_id) === defaultInventoryCard);
  if (userCard) {
    userCard.userDefault = true;
    userCard.inventoryDefault = defaultCard === defaultInventoryCard;
  }
  if (inventoryCard) {
    inventoryCard.userDefault = defaultCard === defaultInventoryCard;
    inventoryCard.inventoryDefault = true;
  }
  return {
    userCard,
    inventoryCard
  };
});
export const initialPaymentStateSelector = createSelector(deliverToFulfillmentCenterSelector, defaultInventoryAndUserCardsSelector, (isDeliverToWarehouse, defaultMethods) => {
  if (isDeliverToWarehouse) {
    const {
      userCard,
      inventoryCard
    } = defaultMethods;
    if (userCard || inventoryCard) {
      return PAYMENT_STATES.INVENTORY_PREFILLED_CARD;
    }
    return PAYMENT_STATES.INVENTORY_ENTER_PAYMENT_DETAILS;
  }
  return PAYMENT_STATES.GATHERING_ADDRESS_INFO;
});
export const isDeliveryToCustomerSelector = createSelector(deliverToFulfillmentCenterSelector, deliverToFulfillmentCenter => !deliverToFulfillmentCenter);
export const isCloseButtonShownOnPaymentPopupSelector = createSelector(paymentStateSelector, paymentState => !STATUSES_WHEN_CLOSE_BUTTON_SHOWN.includes(paymentState));
export const rootInventoriesCardsSelector = createSelector(deliveryMustBeDoneToInventoriesIdsSelector, inventoriesPaymentSettingsSelector, (inventories, settings) => {
  if (!settings.length) {
    return [];
  }
  const selectedInventoriesSettings = inventories.map(id => settings.find(s => s.inventoryId === id));
  const cards = getCardsByInventories(selectedInventoriesSettings);
  return cards.sort(sortCards);
});
export const inventoriesCardsSelector = createSelector(rootInventoriesCardsSelector, cards => cards);
export const canSelectAnotherCardSelector = createSelector(inventoriesCardsSelector, cards => Boolean(cards.length));
export const preselectedCardSelector = createSelector(defaultInventoryAndUserCardsSelector, ({
  userCard,
  inventoryCard
}) => {
  return formatCard(userCard || inventoryCard);
});
export const preselectedCardInventoryIdSelector = createSelector(selectedCardSelector, preselectedCardSelector, (selectedId, {
  _id
}) => selectedId || _id);
export const selectedByUserCardToPaySelector = createSelector(rootInventoriesCardsSelector, preselectedCardInventoryIdSelector, (cards, selectedId) => {
  return cards.find(({
    _id
  }) => _id === selectedId);
});
export const preselectedCardHolderNameSelector = createSelector(preselectedCardSelector, ({
  holder
}) => holder);
export const preselectedCardBrandNameSelector = createSelector(preselectedCardSelector, ({
  brand
}) => brand);
export const preselectedCardNumberSelector = createSelector(preselectedCardSelector, ({
  number
}) => number);
export const preselectedCardUserDefaultSelector = createSelector(preselectedCardSelector, ({
  userDefault
}) => userDefault);
export const preselectedCardInventoryDefaultSelector = createSelector(preselectedCardSelector, ({
  inventoryDefault
}) => inventoryDefault);
export const isSaveNewPaymentMethodAvailableSelector = createSelector(deliveryMustBeDoneToInventoriesIdsSelector, orderForCustomStoreSelector, (ids, customStoreId) => ids.length <= 1 && !customStoreId);
export const inventoryPaymentMethodFormSelector = createSelector(state => state.form, form => form[PAYMENT_DETAILS_FORM]);
export const errorInventoryPaymentPopupSelector = createSelector(inventoryPaymentMethodFormSelector, form => {
  if (!form) {
    return null;
  }
  const {
    syncErrors,
    fields
  } = form;
  if (!syncErrors || !fields) {
    return null;
  }
  return buildError(syncErrors, fields);
});
export const isDefaultPaymentMethodsExistsSelector = createSelector(defaultInventoryAndUserCardsSelector, ({
  userCard,
  inventoryCard
}) => Boolean(userCard || inventoryCard));
export const isBackButtonShownSelector = createSelector(isDefaultPaymentMethodsExistsSelector, paymentStateSelector, (isExists, paymentState) => isExists && paymentState !== PAYMENT_STATES.INVENTORY_PREFILLED_CARD || !isExists && paymentState !== PAYMENT_STATES.INVENTORY_ENTER_PAYMENT_DETAILS);
export const isSelectCardShownSelector = createSelector(rootInventoriesCardsSelector, cards => Boolean(cards.length));
export const deliveryAddressSelector = createSelector(deliverySelector, delivery => delivery.address);
export const deliveryCountrySelector = createSelector(deliverySelector, delivery => delivery.country);
export const zipErrorSelector = createSelector(deliverySelector, delivery => {
  const {
    err
  } = delivery;
  const error = err;
  if (error && error.isZipError) {
    return error.message;
  }
  return null;
});
export { acceptContractSelector, deliverySelector, isDeliveryAddressValidSelector };
export const inventoryCreditsHasFulfilledBuyTransactionsCheckoutSelector = createSelector(rootSelector, checkout => checkout.inventoryCreditsHasFulfilledBuyTransactions);
export const isCreditsMoreToBuyFlowSelector = createSelector(rootSelector, checkout => checkout.isCreditsMoreToBuyFlow);
export const isTOSAcceptedSelector = createSelector(rootSelector, checkout => checkout.isTOSAccepted);
export const isTOSValidForCheckoutSelector = createSelector(isTOSAcceptedSelector, unauthenticatedUserAlreadyExistsSelector, (isTOSAccepted, unauthenticatedUserAlreadyExists) => {
  if (unauthenticatedUserAlreadyExists) {
    return true;
  }
  return isTOSAccepted;
});
export const isTOSCheckboxShownSelector = createSelector(isLoggedInSelector, unauthenticatedUserAlreadyExistsSelector, (isLoggedIn, unauthenticatedUserAlreadyExists) => !isLoggedIn && !unauthenticatedUserAlreadyExists);
export const isPaymentWithCreditsAvailableSelector = createSelector(inventoriesSelector, deliveryMustBeDoneToInventoriesIdsSelector, userIdSelector, checkoutAvailableCreditsSelector, inventoryCreditsHasFulfilledBuyTransactionsCheckoutSelector, isCreditsMoreToBuyFlowSelector, hasSomeCreditsAvailableSelector, shownPopupSelector, (inventories, ids, userId, credits, inventoryCreditsHasFulfilledBuyTransactions, isCreditsMoreToBuyFlow, hasSomeCreditsAvailable, shownPopup) => {
  const inventory = inventories.find(inv => inv._id === ids[0]);
  const isAdmin = userId && inventory && canUserManagePermissions(inventory, userId);
  if (isCreditsMoreToBuyFlow) {
    return false;
  }
  if (!inventoryCreditsHasFulfilledBuyTransactions) {
    return false;
  }
  if (!hasSomeCreditsAvailable) {
    return false;
  }
  if (shownPopup === INVENTORY_BUY_CREDITS_POPUP) {
    return false;
  }
  return ids.length === 1 && (isAdmin || credits > 0);
});
export const searchReplacementOrderValueSelector = createSelector(replacementOrderStateSelector, state => state.searchValue);
export const showItemsListSelector = createSelector(replacementOrderStateSelector, state => Boolean(state.searchValue.length > 1 && !state.selectedItemId));
export const itemsIdsSelector = createSelector(replacementOrderStateSelector, state => state.items.ids.slice(0, 10));
export const isMoreThenTenReplacementItemsSelector = createSelector(replacementOrderStateSelector, state => state.items.ids.length > MAX_REPLACEMENT_ITEMS_PER_PAGE - 1);
export const itemsDataSelector = createSelector(replacementOrderStateSelector, state => {
  var _state$items$data;
  return (_state$items$data = state.items.data) !== null && _state$items$data !== void 0 ? _state$items$data : [];
});
export const isLoadingSelector = createSelector(replacementOrderStateSelector, state => state.isLoading);
export const replacementOrderItemByIdSelector = createSelector((_, {
  id
}) => id, itemsDataSelector, (id, data) => {
  var _data$id;
  return (_data$id = data[id]) !== null && _data$id !== void 0 ? _data$id : {};
});
export const isSharedReplacementItemSelector = createSelector(replacementOrderItemByIdSelector, item => item.isShared);
export const isInBoxReplacementItemSelector = createSelector(replacementOrderItemByIdSelector, item => {
  return !isBox(item) && Boolean(item.boxItemsId);
});
export const isMultipleColorsReplacementItemSelector = createSelector(itemsDataSelector, replacementOrderItemByIdSelector, (data, currentItem) => {
  var _currentItem$variants;
  if (((_currentItem$variants = currentItem.variants) === null || _currentItem$variants === void 0 ? void 0 : _currentItem$variants.length) !== 1) {
    return false;
  }
  return Object.values(data).some(item => {
    return Boolean(item.asRelatedItemId) && item.asRelatedItemId === currentItem.asRelatedItemId && item.variants.length === 1 && item.variants[0].variantIndex !== currentItem.variants[0].variantIndex;
  });
});
export const replacementItemColorSelector = createSelector(replacementOrderItemByIdSelector, item => {
  var _item$prod, _item$prod$variants$i;
  return (_item$prod = item.prod) === null || _item$prod === void 0 ? void 0 : (_item$prod$variants$i = _item$prod.variants[item.variants[0].variantIndex]) === null || _item$prod$variants$i === void 0 ? void 0 : _item$prod$variants$i.color;
});
export const selectedItemIdSelector = createSelector(replacementOrderStateSelector, state => state.selectedItemId);
export const isOriginalItemSelectedSelector = createSelector(selectedItemIdSelector, Boolean);
export const commentsSelector = createSelector(replacementOrderStateSelector, state => state.comments);
export const isCorrectCommentSelector = createSelector(commentsSelector, comments => comments.length > MAX_COMMENT_LENGTH);
export const isBtnDisabledSelector = createSelector(commentsSelector, selectedItemIdSelector, isCorrectCommentSelector, (str, id, isCorrectComment) => !str.length || !id || isCorrectComment);
export const selectedReplacementOrderItemSelector = createSelector(selectedItemIdSelector, itemsDataSelector, (id, data) => id ? data[id] : null);
export const replacementOrderItemDisabledSelector = createSelector(replacementOrderItemByIdSelector, cartItemsSelector, (item, cartItems) => {
  var _item$status, _item$prod2, _item$prod3;
  const cartItem = cartItems[0];
  if (!cartItems || !item) {
    return null;
  }
  if (checkIsTenantRestriction(item)) {
    return TENANT_ERROR_TEXT;
  }
  if (((_item$status = item.status) !== null && _item$status !== void 0 ? _item$status : 0) < PREPAYMENT_RECEIVED && !isCancelledItem(item)) {
    var _item$status2;
    const str = 'Inappropriate item status.';
    return item.status === DISPLAY_ALL || !item.status ? str + " Item hasn't been placed." : str + ` Item has "${ORDER_STATUSES[(_item$status2 = item.status) !== null && _item$status2 !== void 0 ? _item$status2 : 0]}" status`;
  }
  if (!cartItem.isSample && !cartItem.isPrintedSample && (item.isPrintedSample || item.isSample)) {
    return "This item is a 'sample', but the item in the cart isn't.";
  }
  if ((cartItem.isSample || cartItem.isPrintedSample) && !item.isPrintedSample && !item.isSample) {
    return "This item isn't a 'sample', but the item in the cart is.";
  }
  if (item !== null && item !== void 0 && (_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && _item$prod2.forbiddenForBox && !!cartItem.boxItemsId) {
    return 'Replacement item is forbidden for adding to boxes.';
  }
  if (item !== null && item !== void 0 && (_item$prod3 = item.prod) !== null && _item$prod3 !== void 0 && _item$prod3.forbiddenForInventory && !!cartItem.inventoryId) {
    return 'Replacement item is forbidden for adding to inventories.';
  }
  if (item.replacementItemStatus === REPLACEMENT_ITEM_STATUSES.REPLACEMENT_ITEM) {
    return 'This item is already involved in replacement.';
  }
  return null;
});
export const replacementItemSelector = createSelector(cartItemsSelector, taxesPriceSelector, orderTotalPriceSelector, absoluteDiscountSelector, isShippingFreeSelector, isSetupFeeFreeSelector, isTaxFreeSelector, (cartItems, tax, total, discount, isShippingFree, isSetupFeeFree, isTaxFree) => {
  var _cartItems$, _item$sampleDeduction;
  const item = _objectSpread({}, (_cartItems$ = cartItems === null || cartItems === void 0 ? void 0 : cartItems[0]) !== null && _cartItems$ !== void 0 ? _cartItems$ : {});
  if (isSetupFeeFree) {
    item.setupFeeDeductionDiscount = getSetupFeeDeductionDiscount(item);
  }
  const productionCost = productionCostCalculate(item);
  const qty = item.quantity;
  const setupFeeForBoxProduct = getSetupFeeForBoxProduct(item, cartItems, item.isSample || item.isPrintedSample);
  const setupFeeForLogoProduct = getSetupFeeForLogoProduct(item, cartItems, getRelatedItemsBasedOnProvidedId(item, cartItems, true), item.isSample || item.isPrintedSample);
  const setupFee = setupFeeForItem(item, setupFeeForBoxProduct, setupFeeForLogoProduct);
  const screenPrice = Price.getScreenPriceForItem({
    item,
    setupFee
  });
  const itemPrice = Price.getPricePerItem({
    assemblyFee: item.prod.assemblyFee,
    quantity: qty,
    screenPrice,
    totalPriceForItems: productionCost
  });
  const shipping = getItemShippingCost(getDataForGettingItemShippingCost(item, [item]));
  return {
    itemPrice,
    qty,
    productionCost,
    setupFee,
    shipping,
    tax,
    discount: discount + ((_item$sampleDeduction = item === null || item === void 0 ? void 0 : item.sampleDeductionDiscount) !== null && _item$sampleDeduction !== void 0 ? _item$sampleDeduction : 0),
    total,
    isFreeShipping: !!isShippingFree,
    isFreeTax: !!isTaxFree,
    isFreeSetupFee: !!isSetupFeeFree
  };
});
export const originalItemSelector = createSelector(selectedReplacementOrderItemSelector, origItem => {
  var _origItem$sampleDeduc;
  if (!origItem) {
    return {
      itemPrice: 0,
      qty: 0,
      productionCost: 0,
      shipping: 0,
      setupFee: 0,
      tax: 0,
      discount: 0,
      total: 0,
      isFreeShipping: false,
      isFreeTax: false,
      isFreeSetupFee: false
    };
  }
  const {
    total,
    qty,
    productionCost,
    setupFee,
    itemPrice,
    shipping,
    tax,
    discount,
    isFreeShipping,
    isFreeTax,
    isFreeSetupFee
  } = getItemPriceInfo(origItem);
  return {
    itemPrice,
    qty,
    productionCost,
    shipping,
    setupFee,
    tax,
    discount: discount + ((_origItem$sampleDeduc = origItem === null || origItem === void 0 ? void 0 : origItem.sampleDeductionDiscount) !== null && _origItem$sampleDeduc !== void 0 ? _origItem$sampleDeduc : 0),
    total,
    isFreeShipping,
    isFreeTax,
    isFreeSetupFee
  };
});
export const replacementCommentSelector = createSelector(replacementOrderStateSelector, replacementOrder => {
  return replacementOrder.comments;
});
export const replacementIsSilentSelector = createSelector(replacementOrderStateSelector, replacementOrder => {
  return replacementOrder.isSilent;
});
export const replacementPriceRangeSelector = createSelector(replacementOrderStateSelector, replacementPriceRangeValueSelector, (replacementOrderState, defaultValue) => {
  return replacementOrderState.replacementPriceRangeChanged ? replacementOrderState.replacementPriceRange : defaultValue;
});
export const isSuperAdminSelector = createSelector(userAdminRoleSelector, userAdminRole => {
  return userAdminRole === USER_ROLES.SUPER_ADMIN;
});
export const canEditReplacementPriceRangeSelector = createSelector(userAdminPoliciesSelector, userAdminRolePolicies => {
  return userAdminRolePolicies.includes(POLICIES.CHECKOUT_REPLACEMENT_PERCENT_EDIT);
});
export const priceDifferenceStrSelector = createSelector(replacementItemSelector, originalItemSelector, replacementPriceRangeSelector, (replacementItem, originalItem, replacementPercentRange) => {
  return getDifferenceStr({
    freeReplacementPercent: Number(replacementPercentRange),
    replacementItem,
    originalItem
  });
});
export const replacementItemFinalTotalSelector = createSelector(replacementItemSelector, originalItemSelector, replacementPriceRangeSelector, (replacementItem, originalItem, replacementPercentRange) => {
  return getFinalTotal({
    freeReplacementPercent: Number(replacementPercentRange),
    replacementItem,
    originalItem
  });
});
export const hasDifferentQtySelector = createSelector(originalItemSelector, replacementItemSelector, isOriginalItemSelectedSelector, (originalItem, replacementItem, isOriginalItemSelected) => isOriginalItemSelected && originalItem && replacementItem && originalItem.qty !== replacementItem.qty);
export const isQtyDifferentSelector = createSelector(replacementItemSelector, originalItemSelector, (repItem, originalItem) => repItem.qty !== originalItem.qty);
export const orderDataForInvoiceSelector = createSelector(cartRootSelector, cartItemsSelector, resellerInvoiceNumSelector, invoiceLineItemsSelector, deliverySelector, deliverToFulfillmentCenterSelector, invoiceDiscountDataSelector, (cart, items, invoiceOrderNum, lineItems, delivery, deliverToFulfillmentCenter, invoiceDiscountData) => {
  var _ref;
  const tenantStoreSettings = (_ref = window.swag && window.swag.storeSettings) !== null && _ref !== void 0 ? _ref : null;
  const {
    email,
    address,
    zip,
    city,
    country,
    state,
    unit,
    company,
    fname,
    lname
  } = delivery;
  const {
    freeSetupFee,
    freeShipping,
    freeTax,
    discountType,
    discountValue
  } = invoiceDiscountData;
  const updatedItems = items.map((item, index) => {
    const lineItem = lineItems[index];
    if (!lineItem) {
      return item;
    }
    const itemPrice = parseFloat(lineItem.userItemPrice) * 100 * item.quantity;
    const itemTaxes = parseFloat(lineItem.taxes) * 100;
    return _objectSpread({}, item, {
      tax: itemTaxes,
      price: itemPrice,
      resellerInvoiceMultiplier: lineItem.multiplier,
      IS_FREE_SETUP_FEE: freeSetupFee,
      IS_FREE_SHIPPING: freeShipping
    });
  });
  const promocodeDiscount = discountType === PromocodeMeasurementEnum.ABSOLUTE ? discountValue * 100 : discountValue;
  const orderData = {
    items: updatedItems,
    email,
    country,
    state,
    zip,
    city,
    address,
    unit,
    company,
    firstName: fname,
    lastName: lname,
    fnameShipping: fname,
    lnameShipping: lname,
    deliverToFulfillmentCenter,
    num: '12345',
    tenant: window.swag.tenant,
    IS_FREE_SHIPPING: freeShipping,
    IS_TAXFREE: freeTax,
    measurement: discountType,
    promocodeDiscount,
    isTaxExempt: Boolean(cart.isTaxExempt),
    isConfirmed: true,
    paymentMethod: ACH
  };
  return formatOrderDataForInvoice({
    order: _objectSpread({}, orderData, recalculateProceededOrder(orderData), {
      num: invoiceOrderNum || '12345'
    }),
    invoiceType: InvoiceType.ORDER,
    tenantStoreSettings
  });
});
export const resellerInvoiceTotalSelector = createSelector(orderDataForInvoiceSelector, data => {
  return parseInt((data === null || data === void 0 ? void 0 : data.orderPrices.total) || '0');
});
export const resellerInvoiceNotificationSelector = createSelector(resellerInvoiceTotalSelector, swagSpaceOrderTotalSelector, (resellerInvoiceTotal, swagSpaceOrderTotal) => {
  const profit = resellerInvoiceTotal - swagSpaceOrderTotal;
  if (profit < 0) {
    return `Note that you will lose $${Price.formatPriceToString(Math.abs(profit))} on this order due to discount`;
  }
  return '';
});
export const proceedToCheckoutButtonLabelSelector = createSelector(resellerAdminModeSelector, resellerAdminMode => {
  if (checkIsInvoiceMode(resellerAdminMode)) {
    return 'Proceed to Invoice Creation';
  }
  if (checkIsPresentationMode(resellerAdminMode)) {
    return 'Proceed to Presentation Creation';
  }
  return 'Proceed to Checkout';
});
export const deliveryInternationalErrorSelector = createSelector(deliverySelector, noInternationalShippingItemsSelector, (delivery, noInternationalShippingItems) => getInternationalDeliveryErrorMsg(delivery.country, noInternationalShippingItems));