function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { checkIsResellerTenant } from 'swag-common/utils/check-is-reseller-tenant.util';
import { HEADER_DATA_FOR_INVOICE } from 'swag-common/constants/tenant.constants';
import { LOGO_LINK } from 'swag-common/constants/tenant';
export const getHeaderInfoForInvoice = (tenant, tenantStoreSettings) => {
  if (checkIsResellerTenant(tenant) && tenantStoreSettings) {
    const {
      address,
      companyPhone,
      companyShowPhone,
      companyEmail,
      companyShowEmail,
      logoUrl
    } = tenantStoreSettings;
    const phone = companyShowPhone && companyPhone ? companyPhone : '';
    const email = companyShowEmail && companyEmail ? companyEmail : '';
    return {
      address: `${address === null || address === void 0 ? void 0 : address.billingAddress}, ${address === null || address === void 0 ? void 0 : address.billingCity}, ${address === null || address === void 0 ? void 0 : address.billingState} ${address === null || address === void 0 ? void 0 : address.billingZip},
      ${address === null || address === void 0 ? void 0 : address.billingCountry}`,
      phone,
      email,
      logo: logoUrl || ''
    };
  }
  const logo = LOGO_LINK[tenant] || '';
  return _objectSpread({}, HEADER_DATA_FOR_INVOICE[tenant], {
    logo
  });
};