import { createSelector } from 'reselect';
export const boxAndInventorySelector = createSelector(() => {
  /* empty */
}, () => {
  /* empty */
});
export const shopCustomColorsSelector = () => ({
  secondaryColor: '',
  primaryColor: ''
});
export const internationalShippingSelector = () => {
  /* empty */
};