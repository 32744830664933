import { BundleType } from 'swag-common/constants/main-swag.constants';
export const getBundleTypeByTenant = tenant => {
  if (!tenant) {
    return BundleType.SWAG;
  }
  const isPredefinedBundle = Object.values(BundleType).includes(tenant);
  if (isPredefinedBundle) {
    return tenant;
  }
  return BundleType.DYNAMIC;
};